<template>
    <div>
        404
    </div>
</template>

<script>
export default {
    name: 'not-found',
    components: {},
    data () {
        return {
        };
    },
    created() {},
    methods: {}
}

</script>
<style lang='less' scoped>
</style>